import ContentRow from "../Project/Components/ContentRow"
import Topbar from "../Project/Components/Topbar"
import { get_object } from "../exporter"
import "./styles/weatherStyles.css"


let obj = get_object("WeatherDisplay")


export default function WeatherDisplay() {

    return (
        <>
            <Topbar></Topbar>
            <h2 className='Project-title'>Weather Display</h2>
            <div className="row-content-container">
                <ContentRow img={obj.imgs[3]} imgMaxWidth={"40em"} imgBasis="38%" imgSide={"right"} imgType={"none"} description={obj.description[0]} />
                <ContentRow img={obj.imgs[0]} imgSide={"left"} imgBasis="38%" imgType={"none"} description={obj.description[1]} listDescBasis="40em" />
                <ContentRow description={obj.description[2]} listDescBasis="80em" />
                <ContentRow description={obj.description[3]} listDescBasis="80em" />
                <div className={"weather-links"}>
                    <p>Link to <button onClick={() => {window.open("https://github.com/lemnar/Sailing-Display-Code", "_blank")}}>code</button></p>
                    <p>Link to <button onClick={() => {window.open("https://docs.google.com/document/d/1VtFtZhF2UCjryb_rqRzqfEgy1m4jbcfZA-o1QWYB1os/edit", "_blank")}}>component list</button></p>
                    <p>More Photos Below!</p>
                </div>
                <div className={"photo-row"}>
                    <div className="img-card" style={{ flex:"0 0 28%" }}>
                        <img src={obj.imgs[1]}></img>
                    </div>
                    <div className="img-card" style={{ flex:"0 0 28%" }}>
                        <img src={obj.imgs[2]}></img>
                    </div>
                    <div className="img-card" style={{ flex:"0 0 28%" }}>
                        <img src={obj.imgs[4]}></img>
                    </div>
                </div>
                <div className={"photo-row"} style={{ marginTop: "-7rem"}}>
                    <div className="img-card" style={{ flex:"0 0 28%" }}>
                        <img src={obj.imgs[5]}></img>
                    </div>
                    <div className="img-card" style={{ flex:"0 0 28%" }}>
                        <img src={obj.imgs[0]}></img>
                    </div>
                </div>
            </div>
        </>
    )
}