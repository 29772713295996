import Topbar from "../Project/Components/Topbar"
import Template from "../Project/Template"


export default function LaptopStand() {


    return (<>
        <Topbar></Topbar>
        <h2 className="Project-title">Laptop Stand</h2>
        <Template name="LaptopStand" imgTypes={["none", "none", "none", "none"]} imgWidths={["32em", "35em", "30em", "28em"]}></Template>
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <h3 style={{ fontSize: "1.5em", margin: "3em 0 0 0" }}>Additional information</h3>
            <p style={{ fontSize: "1.15em" }}>If you have any more questions about the laptop stand, please email me or see some of the additional information about the project that I linked below.</p>
            <p style={{margin:"0 0 2em 0"}}><button style={{ fontSize: "1.1em" }} onClick={() => window.open("https://docs.google.com/document/d/1k5eAJSEhh6o4htpuNKgzll_HzCFjiu6O/edit")} target="_blank" >Laptop Stand Report</button></p>
        </div>

    </>
    )
}

// get laptop stand pictures
