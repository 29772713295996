import "./Template.css"
import Topbar from "./Components/Topbar"
import ContentRow from "./Components/ContentRow"
import all_objects from "../exporter" 

export default function Template({name, imgWidths, imgTypes, children}) {
    let others;
    if (children != undefined) {
        others = children.map((child) =>
    <>{child}</>
    )
    }
    else {
        others = <></>
    }
    let this_page_data = 0
    all_objects.forEach((obj) => {
        if (obj.name == name) {
            this_page_data = obj
        }
    })
    if (this_page_data == 0) {
        return (<p>No information -- check that the name is correct in your declaration of Template</p>)
    }
    
    const objs = Object.keys(this_page_data.description).map((key, index) => 
    <><ContentRow imgType={imgTypes[index]} img={this_page_data.imgs[index]} imgMaxWidth={imgWidths[index] == undefined ? "50em" : imgWidths[index]} description={this_page_data.description[index]} imgSide={index%2 ? "right" : "left"}/></>
    )
    
    return (<>
        <div className="row-content-container">
        {objs}
        {/* <ContentRow img={test.imgs[0]} description={test.description.desc1} image_side="left" /> */}
        {others}
        </div>
    </>)
}