
import { get_object, all_objects } from '../exporter.js';
import ContentRow from '../Project/Components/ContentRow';
import Topbar from '../Project/Components/Topbar';
import video from "../Project/Components/assets/OctoVideo.mov";



export default function Octo() {
  let this_obj = (get_object("OctoOrganizer"));
  if (this_obj == 0 || this_obj == undefined) {
    return (<>no object</>)
  }
  return (
    <>
      <Topbar></Topbar>
      <h2 className='Project-title'><a style={{ textDecoration: "none", color: "#346283" }} target='_blank' href="http://workingrn.online">Octo Organizer</a></h2>
      <div className='row-content-container'>
        <ContentRow img={this_obj.imgs[0]} imgBasis='53%' imgSide="left" imgMaxWidth={"50em"} description={this_obj.description[0]} ></ContentRow>
        <ContentRow img={this_obj.imgs[1]} imgBasis='53%' imgSide="right" imgMaxWidth={"50em"} description={this_obj.description[1]} ></ContentRow>
        <ContentRow img={this_obj.imgs[2]} imgBasis='53%' imgSide="left" imgMaxWidth={"50em"} description={this_obj.description[2]} ></ContentRow>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "2em" }}>
          <p style={{ color: "var(--text)", fontSize: "1.5em", textAlign: "center", margin: 0 }}><button onClick={() => window.open("http://workingrn.online")} target="_blank" style={{ color: "inherit", textDecoration: "none", fontSize: "1.1rem", fontWeight: "bold" }}>Try it out yourself!</button><br></br>Below is some example footage: </p>
          <p style={{ margin: 0, padding: "0 0 2em 0", textAlign: "center", color: "#346283" }}>Please note that if you want to try it out, you can use a fake name and email (there is no verification), and that there are still some bugs.<br></br>
            Additionally, as this is hosted on a free service, it is not currently suitable for online use.</p>
          <video style={{ justifySelf: "center", width: "calc(50% + 20em)", borderRadius: "20px" }} src={video} controls="play" >
          </video>
        </div>
      </div>
    </>
  )
} 