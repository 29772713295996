import ContentRow from "../Project/Components/ContentRow";
import Topbar from "../Project/Components/Topbar";
import Template from "../Project/Template";
import { get_object } from "../exporter";


let obj = get_object("SmartTongs")
export default function SmartTongs() {

    return (

        <>
            <Topbar></Topbar>
            <h2 className='Project-title'>Smart Tongs</h2>
            <div class="row-content-container">
                <ContentRow description={obj.description[0]}></ContentRow>
                <ContentRow img={obj.imgs[0]} description={obj.description[1]}></ContentRow>
                <ContentRow img={obj.imgs[1]} imgSide={"left"} description={obj.description[2]}></ContentRow>
            </div>
        </>
    )
}