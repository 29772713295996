import Topbar from "./Project/Components/Topbar"
import Template from "./Project/Template"

export default function Test() {
    return(<>
    <Topbar></Topbar>
    <h3 className="Project-title">Laptop Stand</h3>
    <Template name="Laptop Stand" imgTypes={["crop"]} imgWidths={["40em", "60em"]} />
    
    </>)
}