import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import Test from './Test';
import Template from "./Project/Template";

import {
  createBrowserRouter,
  RouterProvider, 
} from "react-router-dom";
import Home from './Home/Home';
import NewHome from './NewHome/NewHome.js';
import reportWebVitals from './reportWebVitals';


import Octo from './Pages/Octo.js';
import LaptopStand from './Pages/LaptopStand.js';
import WeatherDisplay from './Pages/WeatherDisplay.js';
import SmartTongs from './Pages/SmartTongs.js';



const router = createBrowserRouter([
  {
    path: "/",
    element: <NewHome />,
  },
  {
    path: '/home/',
    element: <NewHome/>,
  },
  {
    path: "/test/",
    element: <><Test></Test></>,
  },
  {
    path: "/octoorganizer/",
    element: <Octo />
  },
  {
    path: "/laptopstand/",
    element: <LaptopStand></LaptopStand>
  },
  {
    path: '/weatherdisplay/',
    element: <WeatherDisplay></WeatherDisplay>

  },
  {
    path: '/smarttongs/',
    element: <SmartTongs></SmartTongs>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
