import "./ContentRow.css"


export default function ContentRow({ img, imgMaxWidth, imgBasis = "40%", description, imgSide, listDescBasis = "32em", imgType = "none" }) {
    let divClass = "Project-Row"
    let desc = <>None</>
    let imgObj = <></>;
    if (imgSide == "left") {
        divClass = "Project-Row left-image"
    }
    else {
        divClass = "Project-Row right-image"
    }



    if (img != undefined) {
        imgObj = <>
            <div className="img-card" style={{ maxWidth: imgMaxWidth, flex: "0 0 " + imgBasis }}>
                <img src={img}  />
            </div>
        </>
    }


    const h = description[0]
    const d = description.slice(1)
    let basis;


    // description definition
    if (description.length >= 3) {
        console.log(description)
        desc = <>
            <h3 style={{ fontFamily: "Crimson Pro", marginTop: "0", fontSize: "1.5em" }} >{h} </h3>
            {
                d.map((item, index) => <div className="Project-Row-List">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <p className="item-prefix">{index + 1}</p>
                        <p className="project-list-p" style={{ margin: "0", width: "100%" }} >{item}</p></div>
                </div>)
            }
        </>
        basis = listDescBasis
    }
    else {
        desc = <>
            <h3 style={{ fontFamily: "Crimson Pro", marginTop: "0", fontSize: "1.5em" }}> {h} </h3>
            <p className="Project-Row-desc"> {d} </p>
        </>
        basis = "none";
    }

    // return
    return imgSide == "left" ? (
        <>
            <div className={divClass + " " + imgType}>
                {imgObj}
                <div className="Project-Row-Description" style={{ flexBasis: basis }}>
                    {desc}
                </div>
            </div>
        </>
    ) :
        (
            <>
                <div className={divClass + " " + imgType}>
                    <div className="Project-Row-Description" style={{ flexBasis: basis }}>
                        {desc}
                    </div>
                    {imgObj}
                </div>
            </>
        )
}