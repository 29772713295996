import "./Topbar.css"


export default function Topbar({ name }) {

    return (
        <>
            <div className='home-header'>
                <a href="/home/"><h1>Nikolai Woeger - Portfolio</h1></a>
                <button onClick={() => {window.open("mailto:nikolai.woeger@gmail.com")}}>Contact Me</button>
            </div>
        </>
    )
}